<template>
    <header
        :class="{ 'shadow-md': navbarShadowActive }"
        class="sticky top-0 z-40 h-9 w-full transition-shadow duration-300 lg:h-[80px]">
        <LazyModalPersonalConsulting :open-modal="openModal" @close-modal="openModal = false" />
        <div class="h-full w-full bg-shades-white py-5 text-blue-900">
            <div
                class="z-40 mx-auto flex h-full w-full max-w-screen-2xl items-center justify-between gap-6 bg-shades-white px-5 lg:px-8">
                <div
                    ref="logoLink"
                    tabindex="-1"
                    class="flex items-center gap-5 focus-visible:outline-none">
                    <!-- Logo -->
                    <NuxtLink :to="`/${currentCountry !== Country.NONE ? currentCountry : ''}/`">
                        <span class="sr-only">{{ $t('screen_reader.homepage') }}</span>
                        <IconsLogosInstadriveFull
                            class="h-6 fill-blue-400 lg:hidden lg:h-auto lg:w-13 xl:block" />
                        <IconsLogosInstadriveE
                            class="hidden h-6 fill-blue-400 lg:block xl:hidden" />
                    </NuxtLink>

                    <!-- B2B Switch -->
                    <B2BSwitch class="hidden lg:flex">
                        <template #private-text> {{ $t('country_selector.private') }} </template>
                        <template #business-text> {{ $t('country_selector.business') }} </template>
                    </B2BSwitch>
                </div>

                <!-- Desktop menu -->
                <nav class="hidden w-full items-center gap-x-5 lg:flex">
                    <ul class="flex w-full items-center justify-center gap-6">
                        <template v-if="config.public.siteMode === 'B2B' && headerBlok">
                            <li
                                v-for="blok in headerBlok.header_menu"
                                :key="blok._uid"
                                class="font-bold">
                                <StoryblokComponent :blok="blok"></StoryblokComponent>
                            </li>
                        </template>
                        <template v-else>
                            <li>
                                <MegaMenu>
                                    <template #trigger> {{ $t('menu.e_cars') }} </template>

                                    <HeadlessTabGroup>
                                        <div class="flex justify-center bg-blue-100">
                                            <div
                                                class="flex w-full max-w-screen-2xl items-baseline justify-between gap-6 px-8 py-6">
                                                <HeadlessTabList class="flex gap-8 font-bold">
                                                    <HeadlessTab
                                                        v-if="
                                                            usedPromoCars &&
                                                            usedPromoCars.length > 0
                                                        "
                                                        class="ui-selected:text-blue-400 ui-selected:underline decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                        {{ $t('menu.tab_cars.used_cars') }}
                                                    </HeadlessTab>
                                                    <HeadlessTab
                                                        v-if="
                                                            newPromoCars && newPromoCars.length > 0
                                                        "
                                                        class="ui-selected:text-blue-400 ui-selected:underline decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                        {{ $t('menu.tab_cars.new_cars') }}
                                                    </HeadlessTab>
                                                    <HeadlessTab
                                                        v-if="
                                                            headerBlok &&
                                                            headerBlok.preorder_cars &&
                                                            headerBlok.preorder_cars.length > 0
                                                        "
                                                        class="ui-selected:text-blue-400 ui-selected:underline decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                        {{ $t('menu.tab_cars.preorder_cars') }}
                                                    </HeadlessTab>
                                                    <HeadlessTab
                                                        class="ui-selected:text-blue-400 ui-selected:underline decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                        {{ $t('menu.tab_cars.all_brands') }}
                                                    </HeadlessTab>
                                                </HeadlessTabList>
                                            </div>
                                        </div>
                                        <div class="flex justify-center">
                                            <HeadlessTabPanels class="w-full max-w-screen-2xl p-8">
                                                <HeadlessTabPanel
                                                    v-if="
                                                        usedPromoCars && usedPromoCars.length > 0
                                                    ">
                                                    <div class="mb-6 flex justify-between">
                                                        <h2 class="text-2xl font-bold">
                                                            {{
                                                                $t('menu.tab_cars.top_used_cars', {
                                                                    number: usedPromoCars.length,
                                                                })
                                                            }}
                                                        </h2>
                                                        <PopoverClose>
                                                            <IconsXClose
                                                                class="stroke-neutral-500"></IconsXClose>
                                                        </PopoverClose>
                                                    </div>
                                                    <div class="grid grid-cols-3 gap-8">
                                                        <div class="col-span-2 flex flex-col gap-7">
                                                            <div class="grid grid-cols-3 gap-8">
                                                                <CarTileMinimal
                                                                    v-for="car in usedPromoCars"
                                                                    :key="car.id"
                                                                    image-provider="cloudimage"
                                                                    :image-source="car.image"
                                                                    :image-alt="getCarFullName(car)"
                                                                    :name="getCarFullName(car)"
                                                                    :price="
                                                                        getCarPrice(car).fromPrice
                                                                    "
                                                                    :link="
                                                                        buildConfiguratorUrl(
                                                                            car.url_slug,
                                                                        )
                                                                    ">
                                                                </CarTileMinimal>
                                                            </div>

                                                            <NuxtLink
                                                                class="flex w-fit items-center gap-1 self-end text-green-400 underline"
                                                                :to="buildUsedCarsPageUrl()">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.used_cars_button',
                                                                    )
                                                                }}
                                                                <IconsArrowRight
                                                                    class="mt-2 h-5 w-5 stroke-green-400"></IconsArrowRight>
                                                            </NuxtLink>
                                                        </div>
                                                        <div
                                                            v-if="topCar"
                                                            class="relative flex min-h-[434px] w-full flex-col items-center justify-between gap-5 rounded-3xl bg-blue-100 p-6">
                                                            <p class="text-3xl font-bold">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.car_of_the_month',
                                                                    )
                                                                }}
                                                            </p>
                                                            <p class="text-xl">
                                                                {{ getCarFullName(topCar) }}
                                                            </p>
                                                            <NuxtLink
                                                                class="h-11"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                <NuxtImg
                                                                    loading="lazy"
                                                                    :src="topCar.image"
                                                                    :alt="getCarFullName(topCar)"
                                                                    format="webp"
                                                                    class="h-11 w-13 object-cover"
                                                                    provider="cloudimage" />
                                                            </NuxtLink>
                                                            <p class="flex items-baseline gap-2">
                                                                {{ $t('car.before_price') }}
                                                                <span class="text-3xl font-bold">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .fromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span
                                                                    v-if="
                                                                        getCarPrice(topCar)
                                                                            .crossFromPrice
                                                                    "
                                                                    class="text-xl text-neutral-400 line-through">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .crossFromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                {{
                                                                    config.public.siteMode === 'B2B'
                                                                        ? $t('car.after_price_b2b')
                                                                        : $t('car.after_price_b2c')
                                                                }}
                                                            </p>
                                                            <Button
                                                                class="w-full"
                                                                color="green"
                                                                intent="primary"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                {{ $t('car.configure') }}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </HeadlessTabPanel>
                                                <HeadlessTabPanel
                                                    v-if="newPromoCars && newPromoCars.length > 0">
                                                    <div class="mb-6 flex justify-between">
                                                        <h2 class="text-2xl font-bold">
                                                            {{
                                                                $t('menu.tab_cars.top_new_cars', {
                                                                    number: newPromoCars.length,
                                                                })
                                                            }}
                                                        </h2>
                                                        <PopoverClose>
                                                            <IconsXClose
                                                                class="stroke-neutral-500"></IconsXClose>
                                                        </PopoverClose>
                                                    </div>
                                                    <div class="grid grid-cols-3 gap-8">
                                                        <div class="col-span-2 flex flex-col gap-7">
                                                            <div class="grid grid-cols-3 gap-8">
                                                                <CarTileMinimal
                                                                    v-for="car in newPromoCars"
                                                                    :key="car.id"
                                                                    image-provider="cloudimage"
                                                                    :image-source="car.image"
                                                                    :image-alt="getCarFullName(car)"
                                                                    :name="getCarFullName(car)"
                                                                    :price="
                                                                        getCarPrice(car).fromPrice
                                                                    "
                                                                    :link="
                                                                        buildConfiguratorUrl(
                                                                            car.url_slug,
                                                                        )
                                                                    ">
                                                                </CarTileMinimal>
                                                            </div>

                                                            <NuxtLink
                                                                class="flex w-fit items-center gap-1 self-end text-green-400 underline"
                                                                :to="buildNewCarsPageUrl()">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.new_cars_button',
                                                                    )
                                                                }}
                                                                <IconsArrowRight
                                                                    class="mt-2 h-5 w-5 stroke-green-400"></IconsArrowRight>
                                                            </NuxtLink>
                                                        </div>
                                                        <div
                                                            v-if="topCar"
                                                            class="relative flex min-h-[434px] w-full flex-col items-center justify-between gap-5 rounded-3xl bg-blue-100 p-6">
                                                            <p class="text-3xl font-bold">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.car_of_the_month',
                                                                    )
                                                                }}
                                                            </p>
                                                            <p class="text-xl">
                                                                {{ getCarFullName(topCar) }}
                                                            </p>
                                                            <NuxtLink
                                                                class="h-11"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                <NuxtImg
                                                                    loading="lazy"
                                                                    :src="topCar.image"
                                                                    :alt="getCarFullName(topCar)"
                                                                    format="webp"
                                                                    class="h-11 w-13 object-cover"
                                                                    provider="cloudimage" />
                                                            </NuxtLink>
                                                            <p class="flex items-baseline gap-2">
                                                                {{ $t('car.before_price') }}
                                                                <span class="text-3xl font-bold">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .fromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span
                                                                    v-if="
                                                                        getCarPrice(topCar)
                                                                            .crossFromPrice
                                                                    "
                                                                    class="text-xl text-neutral-400 line-through">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .crossFromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                {{
                                                                    config.public.siteMode === 'B2B'
                                                                        ? $t('car.after_price_b2b')
                                                                        : $t('car.after_price_b2c')
                                                                }}
                                                            </p>
                                                            <Button
                                                                class="w-full"
                                                                color="green"
                                                                intent="primary"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                {{ $t('car.configure') }}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </HeadlessTabPanel>
                                                <HeadlessTabPanel
                                                    v-if="
                                                        headerBlok &&
                                                        headerBlok.preorder_cars &&
                                                        headerBlok.preorder_cars.length > 0
                                                    ">
                                                    <div class="mb-6 flex justify-between">
                                                        <h2 class="text-2xl font-bold">
                                                            {{
                                                                $t(
                                                                    'menu.tab_cars.top_preorder_cars',
                                                                    {
                                                                        number: headerBlok
                                                                            .preorder_cars.length,
                                                                    },
                                                                )
                                                            }}
                                                        </h2>
                                                        <PopoverClose>
                                                            <IconsXClose
                                                                class="stroke-neutral-500"></IconsXClose>
                                                        </PopoverClose>
                                                    </div>
                                                    <div class="grid grid-cols-3 gap-8">
                                                        <div class="col-span-2 flex flex-col gap-7">
                                                            <div class="grid grid-cols-3 gap-8">
                                                                <CarTileMinimal
                                                                    v-for="car in headerBlok.preorder_cars"
                                                                    :key="car._uid"
                                                                    image-provider="storyblok"
                                                                    :image-source="
                                                                        car.image.filename
                                                                    "
                                                                    :image-alt="car.image.alt"
                                                                    :name="car.name"
                                                                    :link="
                                                                        getUrlFromStoryblokLink(
                                                                            car.details_link,
                                                                        )
                                                                    ">
                                                                </CarTileMinimal>
                                                            </div>

                                                            <NuxtLink
                                                                class="flex w-fit items-center gap-1 self-end text-green-400 underline"
                                                                :to="buildPreOrderCarsPageUrl()">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.preorder_cars_button',
                                                                    )
                                                                }}
                                                                <IconsArrowRight
                                                                    class="mt-2 h-5 w-5 stroke-green-400"></IconsArrowRight>
                                                            </NuxtLink>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                headerBlok &&
                                                                headerBlok.preorder_top_car_link &&
                                                                headerBlok.preorder_top_car_image &&
                                                                headerBlok.preorder_top_car_price
                                                            "
                                                            class="relative flex min-h-[434px] w-full flex-col items-center justify-between gap-5 rounded-3xl bg-blue-100 p-6 text-center">
                                                            <p class="text-3xl font-bold">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.preorder_of_the_month',
                                                                    )
                                                                }}
                                                            </p>
                                                            <p class="text-xl">
                                                                {{
                                                                    headerBlok.preorder_top_car_name
                                                                }}
                                                            </p>
                                                            <NuxtLink
                                                                class="h-11"
                                                                :to="
                                                                    getUrlFromStoryblokLink(
                                                                        headerBlok.preorder_top_car_link,
                                                                    )
                                                                ">
                                                                <NuxtImg
                                                                    loading="lazy"
                                                                    :src="
                                                                        headerBlok
                                                                            .preorder_top_car_image
                                                                            .filename
                                                                    "
                                                                    :alt="
                                                                        headerBlok
                                                                            .preorder_top_car_image
                                                                            .alt
                                                                    "
                                                                    format="webp"
                                                                    class="h-11 w-13 object-cover" />
                                                            </NuxtLink>
                                                            <p>
                                                                {{
                                                                    $t('preorder.estimated_price')
                                                                }}:
                                                                <span
                                                                    class="px-2 text-3xl font-bold">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            +headerBlok.preorder_top_car_price,
                                                                        )
                                                                    }}
                                                                </span>
                                                            </p>
                                                            <Button
                                                                class="w-full"
                                                                color="green"
                                                                intent="primary"
                                                                :to="
                                                                    getUrlFromStoryblokLink(
                                                                        headerBlok.preorder_top_car_link,
                                                                    )
                                                                ">
                                                                {{ $t('car.configure') }}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </HeadlessTabPanel>
                                                <HeadlessTabPanel>
                                                    <div class="mb-6 flex justify-between">
                                                        <h2 class="text-2xl font-bold">
                                                            {{ $t('menu.tab_cars.our_brands') }}
                                                        </h2>
                                                        <PopoverClose>
                                                            <IconsXClose
                                                                class="stroke-neutral-500"></IconsXClose>
                                                        </PopoverClose>
                                                    </div>
                                                    <div class="grid h-min grid-cols-3 gap-8">
                                                        <div
                                                            v-if="
                                                                brandsUsed && brandsUsed.length > 0
                                                            ">
                                                            <h3 class="mb-4 text-xl font-bold">
                                                                {{
                                                                    $t('menu.tab_cars.used_brands')
                                                                }}
                                                            </h3>

                                                            <div
                                                                class="grid h-min grid-cols-2 gap-4">
                                                                <NuxtLink
                                                                    v-for="brand in brandsUsed"
                                                                    :key="brand._uid"
                                                                    :to="
                                                                        getUrlFromStoryblokLink(
                                                                            brand.link,
                                                                        )
                                                                    "
                                                                    class="flex items-center gap-4 rounded-lg border-1 border-neutral-300 p-3 transition-all duration-300 hover:border-green-400 hover:shadow-md">
                                                                    <img
                                                                        class="h-6 w-6 select-none"
                                                                        style="
                                                                            filter: brightness(0)
                                                                                saturate(100%)
                                                                                invert(14%)
                                                                                sepia(61%)
                                                                                saturate(651%)
                                                                                hue-rotate(158deg)
                                                                                brightness(95%)
                                                                                contrast(99%);
                                                                        "
                                                                        loading="lazy"
                                                                        :alt="`${brand.link_text} ${$t('logo')}`"
                                                                        :src="`/images/brands/${brand.link_text.toLowerCase()}.svg`" />
                                                                    <p>
                                                                        {{ brand.link_text }}
                                                                    </p>
                                                                </NuxtLink>
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                brandsNew && brandsNew.length > 0
                                                            ">
                                                            <h3 class="mb-4 text-xl font-bold">
                                                                {{ $t('menu.tab_cars.new_brands') }}
                                                            </h3>

                                                            <div class="grid grid-cols-2 gap-4">
                                                                <NuxtLink
                                                                    v-for="brand in brandsNew"
                                                                    :key="brand._uid"
                                                                    :to="
                                                                        getUrlFromStoryblokLink(
                                                                            brand.link,
                                                                        )
                                                                    "
                                                                    class="flex items-center gap-4 rounded-lg border-1 border-neutral-300 p-3 transition-all duration-300 hover:border-green-400 hover:shadow-md">
                                                                    <img
                                                                        class="h-6 w-6 select-none"
                                                                        style="
                                                                            filter: brightness(0)
                                                                                saturate(100%)
                                                                                invert(14%)
                                                                                sepia(61%)
                                                                                saturate(651%)
                                                                                hue-rotate(158deg)
                                                                                brightness(95%)
                                                                                contrast(99%);
                                                                        "
                                                                        loading="lazy"
                                                                        :alt="`${brand.link_text} ${$t('logo')}`"
                                                                        :src="`/images/brands/${brand.link_text.toLowerCase()}.svg`" />
                                                                    <p>
                                                                        {{ brand.link_text }}
                                                                    </p>
                                                                </NuxtLink>
                                                            </div>
                                                        </div>

                                                        <div v-else></div>

                                                        <div
                                                            v-if="topCar"
                                                            class="relative flex min-h-[434px] w-full flex-col items-center justify-between gap-5 rounded-3xl bg-blue-100 p-6">
                                                            <p class="text-3xl font-bold">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.car_of_the_month',
                                                                    )
                                                                }}
                                                            </p>
                                                            <p class="text-xl">
                                                                {{ getCarFullName(topCar) }}
                                                            </p>
                                                            <NuxtLink
                                                                class="h-11"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                <NuxtImg
                                                                    loading="lazy"
                                                                    :src="topCar.image"
                                                                    :alt="getCarFullName(topCar)"
                                                                    format="webp"
                                                                    class="h-11 w-13 object-cover"
                                                                    provider="cloudimage" />
                                                            </NuxtLink>
                                                            <p class="flex items-baseline gap-2">
                                                                {{ $t('car.before_price') }}
                                                                <span class="text-3xl font-bold">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .fromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span
                                                                    v-if="
                                                                        getCarPrice(topCar)
                                                                            .crossFromPrice
                                                                    "
                                                                    class="text-xl text-neutral-400 line-through">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .crossFromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                {{
                                                                    config.public.siteMode === 'B2B'
                                                                        ? $t('car.after_price_b2b')
                                                                        : $t('car.after_price_b2c')
                                                                }}
                                                            </p>
                                                            <Button
                                                                class="w-full"
                                                                color="green"
                                                                intent="primary"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                {{ $t('car.configure') }}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </HeadlessTabPanel>
                                            </HeadlessTabPanels>
                                        </div>
                                    </HeadlessTabGroup>
                                </MegaMenu>
                            </li>

                            <li>
                                <NuxtLink
                                    :to="buildSalesPageUrl()"
                                    class="font-bold hover:text-blue-400"
                                    >{{ $t('menu.sale') }}</NuxtLink
                                >
                            </li>

                            <li>
                                <MegaMenu>
                                    <template #trigger> {{ $t('menu.knowledge') }} </template>

                                    <div class="flex justify-center">
                                        <div
                                            class="flex w-full max-w-screen-2xl flex-col gap-6 p-8">
                                            <button class="self-end">
                                                <PopoverClose>
                                                    <IconsXClose
                                                        class="stroke-neutral-500"></IconsXClose>
                                                </PopoverClose>
                                            </button>
                                            <HeadlessTabGroup
                                                as="div"
                                                class="grid grid-cols-3 gap-10">
                                                <div class="col-span-2 flex">
                                                    <HeadlessTabList
                                                        class="w-13 flex-none space-y-6">
                                                        <div
                                                            class="flex flex-col items-start gap-3">
                                                            <h2 class="text-2xl font-bold">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_knowledge.knowledge',
                                                                    )
                                                                }}
                                                            </h2>
                                                            <HeadlessTab
                                                                v-if="currentCountry !== Country.FR"
                                                                class="ui-selected:text-blue-400 ui-selected:underline flex w-12 items-center justify-between text-left font-bold decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_knowledge.magazine',
                                                                    )
                                                                }}
                                                                <IconsChevronRight
                                                                    class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                            </HeadlessTab>

                                                            <HeadlessTab
                                                                class="ui-selected:text-blue-400 ui-selected:underline flex w-12 items-center justify-between text-left font-bold decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                                {{ $t('menu.tab_knowledge.faq') }}
                                                                <IconsChevronRight
                                                                    class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                            </HeadlessTab>
                                                            <HeadlessTab
                                                                class="ui-selected:text-blue-400 ui-selected:underline flex w-12 items-center justify-between text-left font-bold decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_knowledge.about_us',
                                                                    )
                                                                }}
                                                                <IconsChevronRight
                                                                    class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                            </HeadlessTab>
                                                        </div>

                                                        <div
                                                            class="flex flex-col items-start gap-3">
                                                            <h2 class="text-2xl font-bold">
                                                                {{
                                                                    $t('menu.tab_knowledge.service')
                                                                }}
                                                            </h2>
                                                            <HeadlessTab
                                                                class="ui-selected:text-blue-400 ui-selected:underline flex w-12 items-center justify-between text-left font-bold decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_knowledge.charging_map',
                                                                    )
                                                                }}
                                                                <IconsChevronRight
                                                                    class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                            </HeadlessTab>
                                                            <HeadlessTab
                                                                v-if="
                                                                    currentCountry === Country.DE ||
                                                                    currentCountry === Country.AT
                                                                "
                                                                class="ui-selected:text-blue-400 ui-selected:underline flex w-12 items-center justify-between text-left font-bold decoration-blue-400 decoration-2 underline-offset-4 focus-visible:outline-none">
                                                                {{
                                                                    $t('menu.tab_knowledge.e_bonus')
                                                                }}
                                                                <IconsChevronRight
                                                                    class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                            </HeadlessTab>
                                                        </div>
                                                    </HeadlessTabList>

                                                    <HeadlessTabPanels class="flex-grow">
                                                        <HeadlessTabPanel
                                                            v-if="currentCountry !== Country.FR">
                                                            <p class="mb-4 text-xl font-bold">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_knowledge.our_topics',
                                                                    )
                                                                }}
                                                            </p>
                                                            <div class="flex flex-col gap-4">
                                                                <NuxtLink
                                                                    class="flex items-center gap-3 underline"
                                                                    to="https://magazin.insta-drive.com/leasing-finanzierung-co/">
                                                                    <div
                                                                        class="flex place-content-center rounded-full bg-blue-400 p-3">
                                                                        <IconsCurrencyEuro
                                                                            class="h-5 w-5 stroke-shades-white"></IconsCurrencyEuro>
                                                                    </div>
                                                                    Leasing, Finanzierung & Co
                                                                </NuxtLink>
                                                                <NuxtLink
                                                                    class="flex items-center gap-3 underline"
                                                                    to="https://magazin.insta-drive.com/ratgeber-wartung/">
                                                                    <div
                                                                        class="grid place-content-center rounded-full bg-blue-400 p-3">
                                                                        <IconsCar01
                                                                            class="h-5 w-5 stroke-shades-white"></IconsCar01>
                                                                    </div>
                                                                    Ratgeber & Wartung
                                                                </NuxtLink>
                                                                <NuxtLink
                                                                    class="flex items-center gap-3 underline"
                                                                    to="https://magazin.insta-drive.com/nachhaltigkeit-umweltaspekte/">
                                                                    <div
                                                                        class="grid place-content-center rounded-full bg-blue-400 p-3">
                                                                        <IconsLeaf
                                                                            class="h-5 w-5 fill-shades-white"></IconsLeaf>
                                                                    </div>
                                                                    Nachhaltigkeit & Umweltaspekte
                                                                </NuxtLink>
                                                                <NuxtLink
                                                                    class="flex items-center gap-3 underline"
                                                                    to="https://magazin.insta-drive.com/technologie-innovation/">
                                                                    <div
                                                                        class="grid place-content-center rounded-full bg-blue-400 p-3">
                                                                        <IconsBatteryFull
                                                                            class="h-5 w-5 stroke-shades-white"></IconsBatteryFull>
                                                                    </div>
                                                                    Technologie & Innovation
                                                                </NuxtLink>
                                                                <NuxtLink
                                                                    class="flex items-center gap-1 text-green-400 underline"
                                                                    to="https://magazin.insta-drive.com/ ">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_knowledge.magazine_button',
                                                                        )
                                                                    }}
                                                                    <IconsArrowRight
                                                                        class="mt-2 h-5 w-5 stroke-green-400"></IconsArrowRight>
                                                                </NuxtLink>
                                                            </div>
                                                        </HeadlessTabPanel>

                                                        <HeadlessTabPanel>
                                                            <FaqBrand
                                                                v-if="
                                                                    headerBlok &&
                                                                    headerBlok.faq &&
                                                                    headerBlok.faq[0]
                                                                "
                                                                :blok="
                                                                    headerBlok.faq[0]
                                                                "></FaqBrand>
                                                        </HeadlessTabPanel>

                                                        <HeadlessTabPanel class="h-full">
                                                            <div
                                                                v-if="
                                                                    headerBlok &&
                                                                    headerBlok.card_about_us_text &&
                                                                    headerBlok.card_about_us_button_text &&
                                                                    headerBlok.card_about_us_button_link
                                                                "
                                                                class="relative h-full overflow-hidden rounded-3xl bg-green-400 text-blue-50 lg:max-w-14">
                                                                <div
                                                                    class="flex h-full max-h-14 w-full flex-col justify-between gap-4 p-6">
                                                                    <div
                                                                        class="flex min-h-12 flex-col gap-5">
                                                                        <p
                                                                            v-if="
                                                                                headerBlok.card_about_us_text
                                                                            "
                                                                            class="text-5xl font-bold">
                                                                            {{
                                                                                headerBlok.card_about_us_text
                                                                            }}
                                                                        </p>
                                                                    </div>

                                                                    <Button
                                                                        intent="primary"
                                                                        color="blue"
                                                                        :to="
                                                                            getUrlFromStoryblokLink(
                                                                                headerBlok.card_about_us_button_link,
                                                                            )
                                                                        "
                                                                        class="w-full justify-center"
                                                                        >{{
                                                                            headerBlok.card_about_us_button_text
                                                                        }}</Button
                                                                    >
                                                                </div>
                                                            </div>
                                                        </HeadlessTabPanel>

                                                        <HeadlessTabPanel class="h-full">
                                                            <div
                                                                v-if="
                                                                    headerBlok &&
                                                                    headerBlok.card_charging_map &&
                                                                    headerBlok.card_charging_map[0]
                                                                "
                                                                class="relative h-full overflow-hidden rounded-3xl text-blue-50 lg:max-w-14">
                                                                <NuxtImg
                                                                    loading="lazy"
                                                                    format="webp"
                                                                    :src="
                                                                        headerBlok
                                                                            .card_charging_map[0]
                                                                            .background_image
                                                                            .filename
                                                                    "
                                                                    :alt="
                                                                        headerBlok
                                                                            .card_charging_map[0]
                                                                            .background_image.alt
                                                                    "
                                                                    height="400px"
                                                                    class="absolute inset-0 h-full w-full object-cover">
                                                                </NuxtImg>
                                                                <div
                                                                    class="relative flex h-full max-h-14 w-full flex-col justify-between gap-4 bg-blue-900 bg-opacity-20 p-6">
                                                                    <div
                                                                        class="flex min-h-12 flex-col gap-5">
                                                                        <h3
                                                                            v-if="
                                                                                headerBlok
                                                                                    .card_charging_map[0]
                                                                                    .title
                                                                            "
                                                                            class="text-xl lg:text-2xl">
                                                                            {{
                                                                                headerBlok
                                                                                    .card_charging_map[0]
                                                                                    .title
                                                                            }}
                                                                        </h3>
                                                                        <RichText
                                                                            v-if="
                                                                                headerBlok
                                                                                    .card_charging_map[0]
                                                                                    .content
                                                                            "
                                                                            :content="
                                                                                headerBlok
                                                                                    .card_charging_map[0]
                                                                                    .content
                                                                            "></RichText>
                                                                    </div>

                                                                    <Button
                                                                        intent="primary"
                                                                        color="green"
                                                                        :to="
                                                                            getUrlFromStoryblokLink(
                                                                                headerBlok
                                                                                    .card_charging_map[0]
                                                                                    .primary_link,
                                                                            )
                                                                        "
                                                                        class="w-full justify-center"
                                                                        >{{
                                                                            headerBlok
                                                                                .card_charging_map[0]
                                                                                .primary_link_text
                                                                        }}</Button
                                                                    >
                                                                </div>
                                                            </div>
                                                        </HeadlessTabPanel>

                                                        <HeadlessTabPanel
                                                            v-if="
                                                                currentCountry === Country.DE ||
                                                                currentCountry === Country.AT
                                                            ">
                                                            <EBonusTile
                                                                :no-title="true"></EBonusTile>
                                                        </HeadlessTabPanel>
                                                    </HeadlessTabPanels>
                                                </div>

                                                <div
                                                    v-if="
                                                        headerBlok &&
                                                        headerBlok.card_knowledge_tab &&
                                                        headerBlok.card_knowledge_tab[0]
                                                    "
                                                    class="relative h-full max-h-15 min-h-[330px] overflow-hidden rounded-3xl text-blue-50">
                                                    <NuxtImg
                                                        loading="lazy"
                                                        format="webp"
                                                        :src="
                                                            headerBlok.card_knowledge_tab[0]
                                                                .background_image.filename
                                                        "
                                                        :alt="
                                                            headerBlok.card_knowledge_tab[0]
                                                                .background_image.alt
                                                        "
                                                        height="400px"
                                                        class="absolute inset-0 h-full w-full object-cover">
                                                    </NuxtImg>
                                                    <div
                                                        class="relative flex h-full w-full flex-col justify-between gap-4 bg-blue-900 bg-opacity-20 p-6">
                                                        <div class="flex min-h-12 flex-col gap-5">
                                                            <h3
                                                                v-if="
                                                                    headerBlok.card_knowledge_tab[0]
                                                                        .title
                                                                "
                                                                class="text-xl lg:text-2xl">
                                                                {{
                                                                    headerBlok.card_knowledge_tab[0]
                                                                        .title
                                                                }}
                                                            </h3>
                                                            <RichText
                                                                v-if="
                                                                    headerBlok.card_knowledge_tab[0]
                                                                        .content
                                                                "
                                                                :content="
                                                                    headerBlok.card_knowledge_tab[0]
                                                                        .content
                                                                "></RichText>
                                                        </div>

                                                        <Button
                                                            intent="primary"
                                                            color="green"
                                                            :to="
                                                                getUrlFromStoryblokLink(
                                                                    headerBlok.card_knowledge_tab[0]
                                                                        .primary_link,
                                                                )
                                                            "
                                                            class="w-full justify-center"
                                                            >{{
                                                                headerBlok.card_knowledge_tab[0]
                                                                    .primary_link_text
                                                            }}</Button
                                                        >
                                                    </div>
                                                </div>
                                            </HeadlessTabGroup>
                                        </div>
                                    </div>
                                </MegaMenu>
                            </li>
                        </template>
                    </ul>

                    <Button
                        color="blue"
                        intent="primary"
                        class="menu-book-meeting flex-none"
                        @click="openModal = true">
                        {{ $t('menu.book_meeting') }}
                    </Button>

                    <DropdownMenu>
                        <DropdownMenuTrigger v-bind="$attrs">
                            <CountryFlag
                                :desktop="true"
                                class="group-hover:text-blue-400 group-focus:text-blue-400"></CountryFlag>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <template v-if="sortedAlternates.length">
                                <DropdownMenuItem
                                    v-for="alternate in sortedAlternates"
                                    :key="alternate.full_slug">
                                    <CountryFlag
                                        :desktop="true"
                                        :alternate="alternate"></CountryFlag>
                                </DropdownMenuItem>
                            </template>
                            <template v-else>
                                <DropdownMenuItem>
                                    <p class="text-sm">
                                        {{ $t('menu.no_other_countries_available') }}
                                    </p>
                                </DropdownMenuItem>
                            </template>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </nav>

                <!-- Mobile menu -->
                <nav v-if="headerBlok" class="lg:hidden">
                    <!-- Hamburger -->
                    <button class="flex" @click="mobileMenuActive = !mobileMenuActive">
                        <IconsMenu01 class="h-6 w-6 stroke-blue-400" />
                    </button>

                    <!-- Sidemenu realized with a dialog -->
                    <HeadlessTransitionRoot :show="mobileMenuActive" as="template">
                        <HeadlessDialog
                            as="div"
                            :static="true"
                            class="relative z-40 lg:hidden"
                            @close="mobileMenuActive = false">
                            <div class="pointer-events-none fixed inset-0 flex">
                                <HeadlessTransitionChild
                                    as="template"
                                    enter="transition duration-300"
                                    enter-from="translate-x-6 opacity-0"
                                    enter-to="translate-x-0"
                                    leave="transition duration-300"
                                    leave-from="translate-x-0"
                                    leave-to="translate-x-6 opacity-0">
                                    <HeadlessDialogPanel
                                        class="pointer-events-auto z-40 flex w-full flex-col overflow-y-scroll bg-shades-white text-blue-900">
                                        <div
                                            class="sticky top-0 z-10 flex h-9 items-center justify-between border-b-1 border-neutral-200 bg-shades-white p-5">
                                            <button
                                                type="button"
                                                class="flex items-center justify-center transition-opacity duration-300"
                                                :class="subMenuIsOpen ? 'opacity-100' : 'opacity-0'"
                                                tabindex="2"
                                                @click="backFromSubMenu">
                                                <IconsChevronRight
                                                    class="h-6 w-6 rotate-180 stroke-neutral-500" />
                                            </button>

                                            <div>
                                                <Transition
                                                    mode="out-in"
                                                    enter-from-class="opacity-0"
                                                    enter-active-class="transition-opacity duration-150"
                                                    leave-active-class="transition-opacity duration-150"
                                                    leave-to-class="opacity-0">
                                                    <p v-if="mobileMenuSubSubMenu === 'magazine'">
                                                        {{ $t('menu.tab_knowledge.magazine') }}
                                                    </p>
                                                    <p
                                                        v-else-if="
                                                            mobileMenuSubSubMenu === 'e-bonus'
                                                        ">
                                                        {{ $t('menu.tab_knowledge.e_bonus') }}
                                                    </p>
                                                    <p
                                                        v-else-if="
                                                            mobileMenuSubSubMenu === 'charging-map'
                                                        ">
                                                        {{ $t('menu.tab_knowledge.charging_map') }}
                                                    </p>
                                                    <p
                                                        v-else-if="
                                                            mobileMenuSubSubMenu === 'about-us'
                                                        ">
                                                        {{ $t('menu.tab_knowledge.about_us') }}
                                                    </p>
                                                    <p v-else-if="mobileMenuSubSubMenu === 'faq'">
                                                        {{ $t('menu.tab_knowledge.faq') }}
                                                    </p>
                                                    <p v-else-if="mobileMenuSubMenu === 'cars'">
                                                        {{ $t('menu.e_cars') }}
                                                    </p>
                                                    <p
                                                        v-else-if="
                                                            mobileMenuSubMenu === 'knowledge'
                                                        ">
                                                        {{ $t('menu.knowledge') }}
                                                    </p>
                                                </Transition>
                                            </div>

                                            <button
                                                type="button"
                                                class="flex items-center justify-center"
                                                tabindex="2"
                                                @click="mobileMenuActive = false">
                                                <span class="sr-only">{{
                                                    $t('screen_reader.close_navigation')
                                                }}</span>
                                                <IconsXClose class="h-6 w-6 stroke-neutral-500" />
                                            </button>
                                        </div>
                                        <div class="grow">
                                            <template
                                                v-if="
                                                    config.public.siteMode === 'B2B' && headerBlok
                                                ">
                                                <nav class="p-5">
                                                    <ul
                                                        class="flex flex-col space-y-3 text-lg font-bold">
                                                        <li
                                                            v-for="blok in headerBlok.header_menu"
                                                            :key="blok._uid">
                                                            <StoryblokComponent
                                                                :blok="blok"></StoryblokComponent>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </template>
                                            <template v-else>
                                                <Transition
                                                    mode="out-in"
                                                    :name="mobileMenuSubMenusTransition">
                                                    <div
                                                        v-if="mobileMenuSubSubMenu === 'magazine'"
                                                        class="p-5">
                                                        <p class="mb-4 text-xl font-bold">
                                                            {{
                                                                $t('menu.tab_knowledge.our_topics')
                                                            }}
                                                        </p>
                                                        <div class="flex flex-col gap-4">
                                                            <NuxtLink
                                                                class="flex items-center gap-3 underline"
                                                                to="https://magazin.insta-drive.com/leasing-finanzierung-co/">
                                                                <div
                                                                    class="flex place-content-center rounded-full bg-blue-400 p-3">
                                                                    <IconsCurrencyEuro
                                                                        class="h-5 w-5 stroke-shades-white"></IconsCurrencyEuro>
                                                                </div>
                                                                Leasing, Finanzierung & Co
                                                            </NuxtLink>
                                                            <NuxtLink
                                                                class="flex items-center gap-3 underline"
                                                                to="https://magazin.insta-drive.com/ratgeber-wartung/">
                                                                <div
                                                                    class="grid place-content-center rounded-full bg-blue-400 p-3">
                                                                    <IconsCar01
                                                                        class="h-5 w-5 stroke-shades-white"></IconsCar01>
                                                                </div>
                                                                Ratgeber & Wartung
                                                            </NuxtLink>
                                                            <NuxtLink
                                                                class="flex items-center gap-3 underline"
                                                                to="https://magazin.insta-drive.com/nachhaltigkeit-umweltaspekte/">
                                                                <div
                                                                    class="grid place-content-center rounded-full bg-blue-400 p-3">
                                                                    <IconsLeaf
                                                                        class="h-5 w-5 fill-shades-white"></IconsLeaf>
                                                                </div>
                                                                Nachhaltigkeit & Umweltaspekte
                                                            </NuxtLink>
                                                            <NuxtLink
                                                                class="flex items-center gap-3 underline"
                                                                to="https://magazin.insta-drive.com/technologie-innovation/">
                                                                <div
                                                                    class="grid place-content-center rounded-full bg-blue-400 p-3">
                                                                    <IconsBatteryFull
                                                                        class="h-5 w-5 stroke-shades-white"></IconsBatteryFull>
                                                                </div>
                                                                Technologie & Innovation
                                                            </NuxtLink>

                                                            <Button
                                                                class="mt-5 w-full"
                                                                intent="primary"
                                                                color="green"
                                                                to="https://magazin.insta-drive.com/">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_knowledge.magazine_button',
                                                                    )
                                                                }}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            mobileMenuSubSubMenu === 'e-bonus'
                                                        "
                                                        class="p-5">
                                                        <EBonusTile :no-title="true"></EBonusTile>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            mobileMenuSubSubMenu === 'charging-map'
                                                        "
                                                        class="p-5">
                                                        <div
                                                            v-if="
                                                                headerBlok &&
                                                                headerBlok.card_charging_map &&
                                                                headerBlok.card_charging_map[0]
                                                            "
                                                            class="relative h-full overflow-hidden rounded-3xl text-blue-50 lg:max-w-14">
                                                            <NuxtImg
                                                                loading="lazy"
                                                                format="webp"
                                                                :src="
                                                                    headerBlok.card_charging_map[0]
                                                                        .background_image.filename
                                                                "
                                                                :alt="
                                                                    headerBlok.card_charging_map[0]
                                                                        .background_image.alt
                                                                "
                                                                height="400px"
                                                                class="absolute inset-0 -z-10 h-full w-full object-cover">
                                                            </NuxtImg>
                                                            <div
                                                                class="flex h-full max-h-14 w-full flex-col justify-between gap-4 bg-blue-900 bg-opacity-20 p-6">
                                                                <div
                                                                    class="flex min-h-12 flex-col gap-5">
                                                                    <h3
                                                                        v-if="
                                                                            headerBlok
                                                                                .card_charging_map[0]
                                                                                .title
                                                                        "
                                                                        class="text-xl lg:text-2xl">
                                                                        {{
                                                                            headerBlok
                                                                                .card_charging_map[0]
                                                                                .title
                                                                        }}
                                                                    </h3>
                                                                    <RichText
                                                                        v-if="
                                                                            headerBlok
                                                                                .card_charging_map[0]
                                                                                .content
                                                                        "
                                                                        :content="
                                                                            headerBlok
                                                                                .card_charging_map[0]
                                                                                .content
                                                                        "></RichText>
                                                                </div>

                                                                <Button
                                                                    intent="primary"
                                                                    color="green"
                                                                    :to="
                                                                        getUrlFromStoryblokLink(
                                                                            headerBlok
                                                                                .card_charging_map[0]
                                                                                .primary_link,
                                                                        )
                                                                    "
                                                                    class="w-full justify-center"
                                                                    >{{
                                                                        headerBlok
                                                                            .card_charging_map[0]
                                                                            .primary_link_text
                                                                    }}</Button
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else-if="
                                                            mobileMenuSubSubMenu === 'about-us'
                                                        "
                                                        class="p-5">
                                                        <div
                                                            v-if="
                                                                headerBlok &&
                                                                headerBlok.card_about_us_text &&
                                                                headerBlok.card_about_us_button_text &&
                                                                headerBlok.card_about_us_button_link
                                                            "
                                                            class="relative h-full overflow-hidden rounded-3xl bg-green-400 text-blue-50 lg:max-w-14">
                                                            <div
                                                                class="flex h-full max-h-14 w-full flex-col justify-between gap-4 p-6">
                                                                <div
                                                                    class="flex min-h-12 flex-col gap-5">
                                                                    <p
                                                                        v-if="
                                                                            headerBlok.card_about_us_text
                                                                        "
                                                                        class="text-5xl font-bold">
                                                                        {{
                                                                            headerBlok.card_about_us_text
                                                                        }}
                                                                    </p>
                                                                </div>

                                                                <Button
                                                                    intent="primary"
                                                                    color="blue"
                                                                    :to="
                                                                        getUrlFromStoryblokLink(
                                                                            headerBlok.card_about_us_button_link,
                                                                        )
                                                                    "
                                                                    class="w-full justify-center"
                                                                    >{{
                                                                        headerBlok.card_about_us_button_text
                                                                    }}</Button
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-else-if="mobileMenuSubSubMenu === 'faq'"
                                                        class="p-5">
                                                        <FaqBrand
                                                            v-if="
                                                                headerBlok &&
                                                                headerBlok.faq &&
                                                                headerBlok.faq[0]
                                                            "
                                                            :blok="headerBlok.faq[0]"></FaqBrand>
                                                    </div>

                                                    <div v-else-if="mobileMenuSubMenu === 'cars'">
                                                        <HeadlessTabGroup>
                                                            <HeadlessTabList
                                                                class="grid grid-cols-2 gap-4 bg-blue-100 p-5">
                                                                <HeadlessTab
                                                                    v-if="
                                                                        usedPromoCars &&
                                                                        usedPromoCars.length > 0
                                                                    "
                                                                    class="ui-selected:bg-blue-400 ui-selected:text-shades-white grid place-content-center rounded-2xl border-1 border-blue-400 p-4 transition duration-300 focus-visible:outline-none">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_cars.used_cars',
                                                                        )
                                                                    }}
                                                                </HeadlessTab>
                                                                <HeadlessTab
                                                                    v-if="
                                                                        newPromoCars &&
                                                                        newPromoCars.length > 0
                                                                    "
                                                                    class="ui-selected:bg-blue-400 ui-selected:text-shades-white grid place-content-center rounded-2xl border-1 border-blue-400 p-4 transition duration-300 focus-visible:outline-none">
                                                                    {{
                                                                        $t('menu.tab_cars.new_cars')
                                                                    }}
                                                                </HeadlessTab>
                                                                <HeadlessTab
                                                                    v-if="
                                                                        headerBlok &&
                                                                        headerBlok.preorder_cars &&
                                                                        headerBlok.preorder_cars
                                                                            .length > 0
                                                                    "
                                                                    class="ui-selected:bg-blue-400 ui-selected:text-shades-white grid place-content-center rounded-2xl border-1 border-blue-400 p-4 transition duration-300 focus-visible:outline-none">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_cars.preorder_cars',
                                                                        )
                                                                    }}
                                                                </HeadlessTab>
                                                                <HeadlessTab
                                                                    class="ui-selected:bg-blue-400 ui-selected:text-shades-white grid place-content-center rounded-2xl border-1 border-blue-400 p-4 transition duration-300 focus-visible:outline-none">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_cars.all_brands',
                                                                        )
                                                                    }}
                                                                </HeadlessTab>
                                                            </HeadlessTabList>

                                                            <HeadlessTabPanels class="p-5">
                                                                <HeadlessTabPanel
                                                                    v-if="
                                                                        usedPromoCars &&
                                                                        usedPromoCars.length > 0
                                                                    ">
                                                                    <p
                                                                        class="mb-6 text-xl font-bold">
                                                                        {{
                                                                            $t(
                                                                                'menu.tab_cars.top_used_cars',
                                                                                {
                                                                                    number: usedPromoCars.length,
                                                                                },
                                                                            )
                                                                        }}
                                                                    </p>

                                                                    <div
                                                                        class="grid grid-cols-1 gap-8 sm:grid-cols-2">
                                                                        <CarTileMinimal
                                                                            v-for="car in usedPromoCars"
                                                                            :key="car.id"
                                                                            image-provider="cloudimage"
                                                                            :image-source="
                                                                                car.image
                                                                            "
                                                                            :image-alt="
                                                                                getCarFullName(car)
                                                                            "
                                                                            :name="
                                                                                getCarFullName(car)
                                                                            "
                                                                            :price="
                                                                                getCarPrice(car)
                                                                                    .fromPrice
                                                                            "
                                                                            :link="
                                                                                buildConfiguratorUrl(
                                                                                    car.url_slug,
                                                                                )
                                                                            ">
                                                                        </CarTileMinimal>
                                                                    </div>
                                                                    <Button
                                                                        class="mt-6 w-full"
                                                                        color="green"
                                                                        intent="primary"
                                                                        :to="
                                                                            buildUsedCarsPageUrl()
                                                                        ">
                                                                        {{
                                                                            $t(
                                                                                'menu.tab_cars.used_cars_button',
                                                                            )
                                                                        }}
                                                                    </Button>
                                                                </HeadlessTabPanel>
                                                                <HeadlessTabPanel
                                                                    v-if="
                                                                        newPromoCars &&
                                                                        newPromoCars.length > 0
                                                                    ">
                                                                    <p
                                                                        class="mb-6 text-xl font-bold">
                                                                        {{
                                                                            $t(
                                                                                'menu.tab_cars.top_new_cars',
                                                                                {
                                                                                    number: newPromoCars.length,
                                                                                },
                                                                            )
                                                                        }}
                                                                    </p>

                                                                    <div
                                                                        class="grid grid-cols-1 gap-8 sm:grid-cols-2">
                                                                        <CarTileMinimal
                                                                            v-for="car in newPromoCars"
                                                                            :key="car.id"
                                                                            image-provider="cloudimage"
                                                                            :image-source="
                                                                                car.image
                                                                            "
                                                                            :image-alt="
                                                                                getCarFullName(car)
                                                                            "
                                                                            :name="
                                                                                getCarFullName(car)
                                                                            "
                                                                            :price="
                                                                                getCarPrice(car)
                                                                                    .fromPrice
                                                                            "
                                                                            :link="
                                                                                buildConfiguratorUrl(
                                                                                    car.url_slug,
                                                                                )
                                                                            ">
                                                                        </CarTileMinimal>
                                                                    </div>

                                                                    <Button
                                                                        class="mt-6 w-full"
                                                                        color="green"
                                                                        intent="primary"
                                                                        :to="buildNewCarsPageUrl()">
                                                                        {{
                                                                            $t(
                                                                                'menu.tab_cars.new_cars_button',
                                                                            )
                                                                        }}
                                                                    </Button>
                                                                </HeadlessTabPanel>
                                                                <HeadlessTabPanel
                                                                    v-if="
                                                                        headerBlok &&
                                                                        headerBlok.preorder_cars &&
                                                                        headerBlok.preorder_cars
                                                                            .length > 0
                                                                    ">
                                                                    <p
                                                                        class="mb-6 text-xl font-bold">
                                                                        {{
                                                                            $t(
                                                                                'menu.tab_cars.top_preorder_cars',
                                                                                {
                                                                                    number: headerBlok
                                                                                        .preorder_cars
                                                                                        .length,
                                                                                },
                                                                            )
                                                                        }}
                                                                    </p>

                                                                    <div
                                                                        class="grid grid-cols-1 gap-8 sm:grid-cols-2">
                                                                        <CarTileMinimal
                                                                            v-for="car in headerBlok.preorder_cars"
                                                                            :key="car._uid"
                                                                            image-provider="storyblok"
                                                                            :image-source="
                                                                                car.image.filename
                                                                            "
                                                                            :image-alt="
                                                                                car.image.alt
                                                                            "
                                                                            :name="car.name"
                                                                            :link="
                                                                                getUrlFromStoryblokLink(
                                                                                    car.details_link,
                                                                                )
                                                                            ">
                                                                        </CarTileMinimal>
                                                                    </div>

                                                                    <Button
                                                                        class="mt-6 w-full"
                                                                        color="green"
                                                                        intent="primary"
                                                                        :to="
                                                                            buildPreOrderCarsPageUrl()
                                                                        ">
                                                                        {{
                                                                            $t(
                                                                                'menu.tab_cars.preorder_cars_button',
                                                                            )
                                                                        }}
                                                                    </Button>
                                                                </HeadlessTabPanel>
                                                                <HeadlessTabPanel>
                                                                    <div
                                                                        class="flex flex-col gap-6">
                                                                        <div
                                                                            v-if="
                                                                                brandsUsed &&
                                                                                brandsUsed.length >
                                                                                    0
                                                                            ">
                                                                            <h3
                                                                                class="mb-4 text-xl font-bold">
                                                                                {{
                                                                                    $t(
                                                                                        'menu.tab_cars.used_brands',
                                                                                    )
                                                                                }}
                                                                            </h3>

                                                                            <div
                                                                                class="grid h-min grid-cols-2 gap-4">
                                                                                <NuxtLink
                                                                                    v-for="brand in brandsUsed"
                                                                                    :key="
                                                                                        brand._uid
                                                                                    "
                                                                                    :to="
                                                                                        getUrlFromStoryblokLink(
                                                                                            brand.link,
                                                                                        )
                                                                                    "
                                                                                    class="flex items-center gap-4 rounded-lg border-1 border-neutral-300 p-3 transition-all duration-300 hover:border-green-400 hover:shadow-md">
                                                                                    <img
                                                                                        class="h-6 w-6 select-none"
                                                                                        style="
                                                                                            filter: brightness(
                                                                                                    0
                                                                                                )
                                                                                                saturate(
                                                                                                    100%
                                                                                                )
                                                                                                invert(
                                                                                                    14%
                                                                                                )
                                                                                                sepia(
                                                                                                    61%
                                                                                                )
                                                                                                saturate(
                                                                                                    651%
                                                                                                )
                                                                                                hue-rotate(
                                                                                                    158deg
                                                                                                )
                                                                                                brightness(
                                                                                                    95%
                                                                                                )
                                                                                                contrast(
                                                                                                    99%
                                                                                                );
                                                                                        "
                                                                                        loading="lazy"
                                                                                        :alt="`${brand.link_text} ${$t('logo')}`"
                                                                                        :src="`/images/brands/${brand.link_text.toLowerCase()}.svg`" />
                                                                                    <p>
                                                                                        {{
                                                                                            brand.link_text
                                                                                        }}
                                                                                    </p>
                                                                                </NuxtLink>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            v-if="
                                                                                brandsNew &&
                                                                                brandsNew.length > 0
                                                                            ">
                                                                            <h3
                                                                                class="mb-4 text-xl font-bold">
                                                                                {{
                                                                                    $t(
                                                                                        'menu.tab_cars.new_brands',
                                                                                    )
                                                                                }}
                                                                            </h3>
                                                                            <div
                                                                                class="grid grid-cols-2 gap-4">
                                                                                <NuxtLink
                                                                                    v-for="brand in brandsNew"
                                                                                    :key="
                                                                                        brand._uid
                                                                                    "
                                                                                    :to="
                                                                                        getUrlFromStoryblokLink(
                                                                                            brand.link,
                                                                                        )
                                                                                    "
                                                                                    class="flex items-center gap-4 rounded-lg border-1 border-neutral-300 p-3 transition-all duration-300 hover:border-green-400 hover:shadow-md">
                                                                                    <img
                                                                                        class="h-6 w-6 select-none"
                                                                                        style="
                                                                                            filter: brightness(
                                                                                                    0
                                                                                                )
                                                                                                saturate(
                                                                                                    100%
                                                                                                )
                                                                                                invert(
                                                                                                    14%
                                                                                                )
                                                                                                sepia(
                                                                                                    61%
                                                                                                )
                                                                                                saturate(
                                                                                                    651%
                                                                                                )
                                                                                                hue-rotate(
                                                                                                    158deg
                                                                                                )
                                                                                                brightness(
                                                                                                    95%
                                                                                                )
                                                                                                contrast(
                                                                                                    99%
                                                                                                );
                                                                                        "
                                                                                        loading="lazy"
                                                                                        :alt="`${brand.link_text} ${$t('logo')}`"
                                                                                        :src="`/images/brands/${brand.link_text.toLowerCase()}.svg`" />
                                                                                    <p>
                                                                                        {{
                                                                                            brand.link_text
                                                                                        }}
                                                                                    </p>
                                                                                </NuxtLink>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </HeadlessTabPanel>
                                                            </HeadlessTabPanels>
                                                        </HeadlessTabGroup>
                                                    </div>

                                                    <div
                                                        v-else-if="
                                                            mobileMenuSubMenu === 'knowledge'
                                                        "
                                                        class="flex flex-col gap-7 p-5">
                                                        <nav class="flex flex-col gap-6">
                                                            <div class="flex flex-col gap-3">
                                                                <h2 class="text-xl">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_knowledge.knowledge',
                                                                        )
                                                                    }}
                                                                </h2>
                                                                <button
                                                                    v-if="
                                                                        currentCountry !==
                                                                        Country.FR
                                                                    "
                                                                    class="flex items-center justify-between font-bold"
                                                                    @click="
                                                                        changeSubSubMenu('magazine')
                                                                    ">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_knowledge.magazine',
                                                                        )
                                                                    }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </button>
                                                                <button
                                                                    class="flex items-center justify-between font-bold"
                                                                    @click="
                                                                        changeSubSubMenu('faq')
                                                                    ">
                                                                    {{
                                                                        $t('menu.tab_knowledge.faq')
                                                                    }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </button>
                                                                <button
                                                                    class="flex items-center justify-between font-bold"
                                                                    @click="
                                                                        changeSubSubMenu('about-us')
                                                                    ">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_knowledge.about_us',
                                                                        )
                                                                    }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </button>
                                                            </div>
                                                            <div class="flex flex-col gap-3">
                                                                <h2 class="text-xl">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_knowledge.service',
                                                                        )
                                                                    }}
                                                                </h2>

                                                                <button
                                                                    class="flex items-center justify-between font-bold"
                                                                    @click="
                                                                        changeSubSubMenu(
                                                                            'charging-map',
                                                                        )
                                                                    ">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_knowledge.charging_map',
                                                                        )
                                                                    }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </button>
                                                                <button
                                                                    v-if="
                                                                        currentCountry ===
                                                                            Country.DE ||
                                                                        currentCountry ===
                                                                            Country.AT
                                                                    "
                                                                    class="flex items-center justify-between font-bold"
                                                                    @click="
                                                                        changeSubSubMenu('e-bonus')
                                                                    ">
                                                                    {{
                                                                        $t(
                                                                            'menu.tab_knowledge.e_bonus',
                                                                        )
                                                                    }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </button>
                                                            </div>
                                                        </nav>

                                                        <div
                                                            v-if="
                                                                headerBlok &&
                                                                headerBlok.card_knowledge_tab &&
                                                                headerBlok.card_knowledge_tab[0]
                                                            "
                                                            class="relative overflow-hidden rounded-3xl text-blue-50">
                                                            <NuxtImg
                                                                loading="lazy"
                                                                format="webp"
                                                                :src="
                                                                    headerBlok.card_knowledge_tab[0]
                                                                        .background_image.filename
                                                                "
                                                                :alt="
                                                                    headerBlok.card_knowledge_tab[0]
                                                                        .background_image.alt
                                                                "
                                                                height="400px"
                                                                class="absolute inset-0 -z-10 h-full w-full object-cover">
                                                            </NuxtImg>
                                                            <div
                                                                class="flex h-full w-full flex-col justify-between gap-4 bg-blue-900 bg-opacity-20 p-6">
                                                                <div
                                                                    class="flex min-h-12 flex-col gap-5">
                                                                    <h3
                                                                        v-if="
                                                                            headerBlok
                                                                                .card_knowledge_tab[0]
                                                                                .title
                                                                        "
                                                                        class="text-xl lg:text-2xl">
                                                                        {{
                                                                            headerBlok
                                                                                .card_knowledge_tab[0]
                                                                                .title
                                                                        }}
                                                                    </h3>
                                                                    <RichText
                                                                        v-if="
                                                                            headerBlok
                                                                                .card_knowledge_tab[0]
                                                                                .content
                                                                        "
                                                                        :content="
                                                                            headerBlok
                                                                                .card_knowledge_tab[0]
                                                                                .content
                                                                        "></RichText>
                                                                </div>

                                                                <Button
                                                                    intent="primary"
                                                                    color="green"
                                                                    :to="
                                                                        getUrlFromStoryblokLink(
                                                                            headerBlok
                                                                                .card_knowledge_tab[0]
                                                                                .primary_link,
                                                                        )
                                                                    "
                                                                    class="w-full justify-center"
                                                                    >{{
                                                                        headerBlok
                                                                            .card_knowledge_tab[0]
                                                                            .primary_link_text
                                                                    }}</Button
                                                                >
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-else class="flex flex-col gap-7 p-5">
                                                        <nav>
                                                            <div class="flex flex-col gap-3">
                                                                <button
                                                                    class="flex items-center justify-between font-bold"
                                                                    @click="changeSubMenu('cars')">
                                                                    {{ $t('menu.e_cars') }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </button>
                                                                <NuxtLink
                                                                    :to="buildSalesPageUrl()"
                                                                    class="flex items-center justify-between font-bold">
                                                                    {{ $t('menu.sale') }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </NuxtLink>
                                                                <button
                                                                    class="flex items-center justify-between font-bold"
                                                                    @click="
                                                                        changeSubMenu('knowledge')
                                                                    ">
                                                                    {{ $t('menu.knowledge') }}
                                                                    <IconsChevronRight
                                                                        class="ui-selected:stroke-blue-400 h-6 w-6 stroke-blue-900"></IconsChevronRight>
                                                                </button>
                                                            </div>
                                                        </nav>

                                                        <Button
                                                            color="blue"
                                                            intent="primary"
                                                            class="w-full"
                                                            :to="buildSelectorUrl()">
                                                            {{ $t('menu.to_configurator') }}
                                                        </Button>

                                                        <div
                                                            v-if="topCar"
                                                            class="flex w-full flex-col items-center justify-between gap-5 rounded-3xl bg-blue-100 p-6">
                                                            <p class="text-xl font-bold">
                                                                {{
                                                                    $t(
                                                                        'menu.tab_cars.car_of_the_month',
                                                                    )
                                                                }}
                                                            </p>
                                                            <p>
                                                                {{ getCarFullName(topCar) }}
                                                            </p>
                                                            <NuxtLink
                                                                class="h-11"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                <NuxtImg
                                                                    loading="lazy"
                                                                    :src="topCar.image"
                                                                    :alt="getCarFullName(topCar)"
                                                                    format="webp"
                                                                    class="h-11 w-13 object-cover"
                                                                    provider="cloudimage" />
                                                            </NuxtLink>
                                                            <p class="flex items-baseline gap-2">
                                                                {{ $t('car.before_price') }}
                                                                <span class="text-xl font-bold">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .fromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                <span
                                                                    v-if="
                                                                        getCarPrice(topCar)
                                                                            .crossFromPrice
                                                                    "
                                                                    class="text-neutral-400 line-through">
                                                                    {{
                                                                        getLocalizedPrice(
                                                                            getCarPrice(topCar)
                                                                                .crossFromPrice,
                                                                        )
                                                                    }}
                                                                </span>
                                                                {{
                                                                    config.public.siteMode === 'B2B'
                                                                        ? $t('car.after_price_b2b')
                                                                        : $t('car.after_price_b2c')
                                                                }}
                                                            </p>
                                                            <Button
                                                                class="w-full"
                                                                color="green"
                                                                intent="primary"
                                                                :to="
                                                                    buildConfiguratorUrl(
                                                                        topCar.url_slug,
                                                                    )
                                                                ">
                                                                {{ $t('car.configure') }}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </template>
                                        </div>

                                        <div
                                            class="flex justify-between gap-4 border-t-1 border-neutral-200 p-5">
                                            <CountryFlag></CountryFlag>
                                            <div class="flex gap-4">
                                                <CountryFlag
                                                    v-for="alternate in sortedAlternates"
                                                    :key="alternate.full_slug"
                                                    :alternate="alternate"></CountryFlag>
                                            </div>
                                        </div>
                                    </HeadlessDialogPanel>
                                </HeadlessTransitionChild>
                            </div>
                        </HeadlessDialog>
                    </HeadlessTransitionRoot>
                </nav>
            </div>
        </div>
    </header>

    <div class="flex items-center justify-center p-4 pt-0 lg:hidden">
        <B2BSwitch>
            <template #private-text> {{ $t('country_selector.private') }} </template>
            <template #business-text> {{ $t('country_selector.business') }} </template>
        </B2BSwitch>
    </div>
</template>

<script setup lang="ts">
    import type { HeaderStoryblok } from '~/types/component-types-sb';
    import { Country } from '~/types/enums/Country';
    import { StoryblokUrl } from '~/types/enums/StoryblokUrl';
    import { VehicleState } from '~/types/enums/VehicleState';
    import type { Car } from '~/types/interfaces/car/Car';
    import DropdownMenuContent from './ui/dropdown-menu/DropdownMenuContent.vue';
    import { PopoverClose } from 'radix-vue';

    const props = defineProps<{
        currentPath: string | string[];
    }>();
    const config = useRuntimeConfig();
    const alternates = useAlternates();
    const route = useRoute();
    const currentCountry = useCurrentCountry();

    /** Sorted alternates */
    const sortedAlternates = computed(() =>
        alternates.value.sort((a, b) => {
            const aCountry = getCountryCodeFromUrl(a.full_slug);
            const bCountry = getCountryCodeFromUrl(b.full_slug);

            if (bCountry === Country.DE) return 1;
            else if (bCountry === Country.AT && aCountry !== Country.DE) return 1;
            else if (bCountry === Country.CH && aCountry !== Country.DE && aCountry !== Country.AT)
                return 1;
            else return -1;
        }),
    );

    /**
     * Modal personal consulting
     */

    const openModal = ref(false);

    /**
     * Mobile menu
     */
    const mobileMenuActive = ref(false);
    // Mobile menu submenus & subsubmenus
    const mobileMenuSubMenu = ref<'' | 'cars' | 'knowledge'>('');
    const mobileMenuSubSubMenu = ref<
        '' | 'magazine' | 'e-bonus' | 'charging-map' | 'about-us' | 'faq'
    >('');
    const mobileMenuSubMenusTransition = ref<'slide-left' | 'slide-right'>('slide-left');

    const subMenuIsOpen = computed(
        () => mobileMenuSubMenu.value !== '' || mobileMenuSubSubMenu.value !== '',
    );

    function changeSubMenu(location: '' | 'cars' | 'knowledge') {
        mobileMenuSubMenusTransition.value = 'slide-left';
        mobileMenuSubMenu.value = location;
    }
    function changeSubSubMenu(
        location: '' | 'magazine' | 'e-bonus' | 'charging-map' | 'about-us' | 'faq',
    ) {
        mobileMenuSubMenusTransition.value = 'slide-left';
        mobileMenuSubSubMenu.value = location;
    }

    function backFromSubMenu() {
        mobileMenuSubMenusTransition.value = 'slide-right';
        if (mobileMenuSubSubMenu.value !== '') mobileMenuSubSubMenu.value = '';
        else if (mobileMenuSubMenu.value !== '') mobileMenuSubMenu.value = '';
    }

    // Close submenus when menu is closed
    watch(mobileMenuActive, (val) => {
        if (!val) {
            mobileMenuSubSubMenu.value = '';
            mobileMenuSubMenu.value = '';
        }
    });

    /**
     * Get storyblok header blok
     */
    const apiRoute = '/cdn/stories' + buildStoryblokUrl(props.currentPath, StoryblokUrl.HEADER);
    const storyblokApi = useStoryblokApi();

    const { data: headerBlokData } = useAsyncData(`header-${apiRoute}`, () => {
        return storyblokApi.get(apiRoute, {
            version: config.public.env === 'production' ? 'published' : 'draft',
            resolve_links: 'url',
        });
    });

    const headerBlok = computed(() =>
        headerBlokData.value
            ? (headerBlokData.value.data.story.content as HeaderStoryblok)
            : undefined,
    );

    /** Get data for mega menu */
    const { data: promoCars } = useFetch<Car[]>(
        buildCarsUrl(null, {
            promo: 1,
        }),
        { key: 'filtered-cars-promo' },
    );

    const usedPromoCars = computed(() =>
        promoCars.value
            ? promoCars.value
                  .filter((val) => val.vehicle_state === VehicleState.USED_CAR)
                  .slice(0, 6)
            : undefined,
    );

    const newPromoCars = computed(() =>
        promoCars.value
            ? promoCars.value
                  .filter((val) => val.vehicle_state === VehicleState.NEW_CAR)
                  .slice(0, 6)
            : undefined,
    );

    const { data: topCar } = await useFetch<Car>(buildTopPromotedCarUrl(), { key: 'top-car' });

    const { data: brandsNew } = await useFetch(`/api/cms/brands/${currentCountry.value}`, {
        query: {
            site_mode: config.public.siteMode.toLowerCase(),
            vehicle_state: 'new-car',
        },
        key: `brandLinks-${config.public.siteMode.toLowerCase()}`,
    });

    const { data: brandsUsed } = await useFetch(`/api/cms/brands/${currentCountry.value}`, {
        query: {
            site_mode: config.public.siteMode.toLowerCase(),
            vehicle_state: 'used-car',
        },
        key: `brandLinks-${config.public.siteMode.toLowerCase()}-used`,
    });

    /**
     * Activate navbar shadow when not scrolled to top
     */
    const { y } = useWindowScroll();
    const navbarShadowActive = ref(false);

    watch(y, () => {
        if (y.value === 0) {
            navbarShadowActive.value = false;
        } else {
            navbarShadowActive.value = true;
        }
    });

    /**
     * Close menus, when page/route changes
     */
    const logoLink = ref<HTMLInputElement | null>(null);

    watch(
        () => route.path,
        () => {
            mobileMenuActive.value = false;
            if (logoLink.value) logoLink.value.focus();
        },
    );
</script>

<style scoped>
    /* Animations */
    .slide-left-enter-active,
    .slide-left-leave-active {
        @apply transition duration-150;
    }
    .slide-left-enter-from {
        @apply translate-x-5 opacity-0;
    }
    .slide-left-leave-to {
        @apply -translate-x-5 opacity-0;
    }

    .slide-right-enter-active,
    .slide-right-leave-active {
        @apply transition duration-150;
    }
    .slide-right-enter-from {
        @apply -translate-x-5 opacity-0;
    }
    .slide-right-leave-to {
        @apply translate-x-5 opacity-0;
    }
</style>
